require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

module.exports = function (file) {
  return function () {
    return import('@/' + file);
  };
};