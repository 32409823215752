import "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/core-js/modules/es.array.iterator.js";
import "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/core-js/modules/es.promise.js";
import "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/core-js/modules/es.object.assign.js";
import "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue'; // 引入element

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 全局配置elementui的dialog不能通过点击遮罩层关闭

ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI); // 引入封装的router

import router from '@/router/index'; // canvas背景插件

import vueParticleLine from 'vue-particle-line';
import 'vue-particle-line/dist/vue-particle-line.css';
Vue.use(vueParticleLine); // time line css

import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'; // 富文本插件

import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuillEditor); // markdown插件

import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);
import '@/permission';
import { store } from '@/store/index';
Vue.config.productionTip = false; // 路由守卫

import Bus from '@/utils/bus.js';
Vue.use(Bus);
import APlayer from '@moefe/vue-aplayer';
Vue.use(APlayer, {
  defaultCover: 'https://github.com/u3u.png',
  productionTip: true
});
import { auth } from '@/directive/auth'; // 按钮权限指令

auth(Vue);
import uploader from 'vue-simple-uploader';
Vue.use(uploader);
export default new Vue({
  render: function render(h) {
    return h(App);
  },
  router: router,
  store: store
}).$mount('#app'); // console.log(`
//        欢迎使用 Gin-Vue-Admin
//        当前版本:V2.3.6
//        默认自动化文档地址:http://127.0.0.1%s/swagger/index.html
//        默认前端文件运行地址:http://127.0.0.1:8080
//        如果项目让您获得了收益，希望您能请团队喝杯可乐:https://www.gin-vue-admin.com/docs/coffee
// `)