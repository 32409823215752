import axios from 'axios'; // 引入axios

import { Message } from 'element-ui';
import { store } from '@/store/index';
import context from '@/main.js';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 99999
});
var acitveAxios = 0;
var timer;

var showLoading = function showLoading() {
  acitveAxios++;

  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(function () {
    if (acitveAxios > 0) {
      context.$bus.emit("showLoading");
    }
  }, 400);
};

var closeLoading = function closeLoading() {
  acitveAxios--;

  if (acitveAxios <= 0) {
    clearTimeout(timer);
    context.$bus.emit("closeLoading");
  }
}; //http request 拦截器


service.interceptors.request.use(function (config) {
  if (!config.donNotShowLoading) {
    showLoading();
  }

  var token = store.getters['user/token'];
  var user = store.getters['user/userInfo'];
  config.data = JSON.stringify(config.data);
  config.headers = {
    'Content-Type': 'application/json',
    'x-token': token,
    'x-user-id': user.ID
  };
  return config;
}, function (error) {
  closeLoading();
  Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return error;
}); //http response 拦截器

service.interceptors.response.use(function (response) {
  closeLoading();

  if (response.headers["new-token"]) {
    store.commit('user/setToken', response.headers["new-token"]);
  }

  if (response.data.code == 0 || response.headers.success === "true") {
    return response.data;
  } else {
    var msg = response.data.msg || response.data.message;
    Message({
      showClose: true,
      message: msg || decodeURI(response.headers.msg),
      type: response.headers.msgtype || 'error'
    });

    if (response.data.data && response.data.data.reload) {
      store.commit('user/LoginOut');
    }

    return msg ? response.data : response;
  }
}, function (error) {
  closeLoading();
  Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return error;
});
export default service;